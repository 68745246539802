import React, { Component, useEffect, useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/estimate-form'

import { ReviewForInModal } from '../components/reviews-all-modal-popup'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import ReactCompareImage from '../components/before-after-image'
import { TestimonialVideo } from '../components/testimonial_video'

import Breadcrumbs from '../components/breadcrumbs'

import '../styles/projects-gallery.scss'

import Carousel, { Modal, ModalGateway } from 'react-images'
import Slider from 'react-slick-preact-patch'

import MagicSliderDots from 'react-magic-slider-dots'
import 'react-magic-slider-dots/dist/magic-dots.css'

import {
  SliderContainer,
  PrevArrow,
  NextArrow,
} from '../components/slider-components'
import { Maximize } from 'react-feather'

import { RelatedBlogPosts } from '../components/RelatedBlogPosts'
const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`
const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;
  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

const ImageGalleryImage = ({
  fluid,
  fullScreen,
  i,
  setMaxHeight,
  maxHeight,
  windowHeight,
  windowWidth,
  isFullScreen,
  pagePath,
  paddingTop,
  activeImageState,
  ...otherprops
}) => {
  const gatsbyImgRef = useRef()

  return (
    <div
      className="aspect-ratio-box projects-project"
      ref={gatsbyImgRef}
      style={{
        paddingTop,
        display:
          i >= activeImageState - 0 && i <= activeImageState + 0
            ? 'block'
            : 'none',
      }} //lock aspect ratio with padding; no jumping because content size is provided and aspect ratio is computed for each page; lazy load images 3 images inView and images before and after
    >
      <GatsbyImage
        className="image-gallery-image overflow-hidden mb-0 aspect-ratio-box-inside"
        image={getImage(otherprops)}
        // onLoad={e =>
        //   updateActiveImageLoadingState(
        //     activeImageLoadingState.map(([index, state]) => {
        //       if (index == i) return [i, true]
        //       return [index, state]
        //     })
        //   )
        // }
        alt=""
      />
    </div>
  )
}

const settings = {
  accessibility: true,
  dots: true,
  infinite: true,
  // autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  // speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: false,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
  appendDots: (dots) => {
    return <MagicSliderDots dots={dots} numDotsToShow={6} dotWidth={30} />
  },
}

const SliderCarousel = (props) => {
  const { images, activeImageState } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const paddingTop =
    (images.length > 0 && images[0].gatsbyImageData
      ? (images[0].gatsbyImageData.height / images[0].gatsbyImageData.width) *
        100
      : 50) + '%'

  return (
    <SliderContainer className="project-gallery-container">
      {/* {showImageLoader && (
    <Loading className="w-14 h-14 absolute-center z-10" />
  )} */}
      <Slider
        {...settings}
        // beforeChange={(oldIndex, newIndex) => setSelectedIndex(newIndex)}
      >
        {images.map((props, i) => (
          <ImageGalleryImage
            key={i}
            maxHeight={maxHeight}
            windowHeight={windowHeight}
            windowWidth={windowWidth}
            pagePath={props.path}
            isFullScreen={isFullScreen}
            setMaxHeight={setMaxHeight}
            paddingTop={paddingTop}
            activeImageState={selectedIndex}
            {...props}
          />
        ))}
      </Slider>
    </SliderContainer>
  )
}

const ProjectPage = (props) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const activeImageState = useState(
    props.data.contentfulProjects.photoGallery.photos.map((_, i) => [i, false])
  )
  const [activeImageLoadingState, updateActiveImageLoadingState] =
    activeImageState
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const toggleLightbox = (selectedIndex) => {
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(selectedIndex)
  }

  const { isLoading } = props

  const post = props.data.contentfulProjects
  const siteurl = props.data.site.siteMetadata.siteUrl
  const relatedPosts = []

  const { moreInCategory } = props.data

  const images = post.photoGallery.photos.map(
    ({ id, smallerFluid, file, fullscreen, title, ...rest }, i) => {
      return {
        i,
        id: i,
        caption: 'US Quality Construction',
        src: fullscreen.src,
        w: 1200,
        h: 900,
        alt: title,
        thumbnail: smallerFluid.src,
        source: {
          original: file.url,
          regular: file.url,
          fullscreen: fullscreen.images.fallback.src,
        },
        file,
        smallerFluid,
        ...rest,
      }
    }
  )
  const items = images
  // const showImageLoader = !activeImageLoadingState[selectedIndex][1]

  const [city, state] = post.city.split(',')

  return (
    <Layout pageProps={props}>
      <div className="bg-white border-b border-gray-100">
        <nav
          className="flex container mx-auto px-4 py-3"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: '/projects/', label: 'Projects' },
              {
                to: props.location.pathname,
                label: `${post.title} in ${post.city}, ${post.zipCode}`,
              },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={
          post.metaTitle
            ? post.metaTitle
            : post.title + ' in ' + post.city + ' ' + post.zipCode
        }
        description={
          post.metaDescription
            ? post.metaDescription
            : 'See Photos of This Beautiful ' +
              post.title +
              ' Project In ' +
              post.city +
              ' ' +
              post.zipCode +
              ' Completed By US Quality Construction. Request a Free Estimate For Your Project Today!'
        }
        img={post.thumbnail.gatsbyImageData.images.fallback.src}
        twitterCardContent={'summary_large_image'}
      />
      <BlogPostLayout className="relative">
        <article itemScope itemType="https://schema.org/Article">
          <div
            itemProp="publisher"
            itemScope
            itemType="https://schema.org/Organization"
            className="hidden"
          >
            <span itemProp="name" className="hidden">
              US Quality Construction
            </span>
            <span
              itemProp="image"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/wz4em6cftltu/GBdB9YIxsN84AQrhVnl3B/9110bc57717912f5c372fd7487652001/usq-thumb.jpeg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
            <span
              itemProp="logo"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/wz4em6cftltu/k3dicBOhCSc3einkPzkid/eb33b3bd57d726b53480f8208b03b8ca/usqc-thumb.jpg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
          </div>
          <Header>
            <div className="lg:flex container lg:row mx-auto">
              <div
                className={`lg:container py-12 text-center blogimgmw w-full lg:w-2/3 relative ${
                  isFullScreen ? 'z-20000' : 'z-20'
                }`}
                style={{ maxWidth: '900px' }}
              >
                <h1 itemProp="name headline" className="text-left -mt-3 mb-2">
                  {post.title} in {city}, {state} {post.zipCode}
                </h1>
                {post.description && (
                  <p
                    className="text-left text-base text-gray-700 mb-6"
                    dangerouslySetInnerHTML={{
                      __html: post.description.description,
                    }}
                  />
                )}

                <div
                  className="mx-auto overflow-hidden border-radius relative z-20 shadow-lg bg-white"
                  style={{ marginBottom: '-150px' }}
                >
                  <div
                    itemProp="image"
                    itemScope
                    itemType="http://schema.org/ImageObject"
                  >
                    <SliderCarousel images={images} activeImageState={0} />
                  </div>
                  <div className="flex flex-1 items-stretch px-2 md:px-6">
                    <ShareThisPost className="bg-white pr-3 justify-content-start p-1 flex-1">
                      <p className="hidden md:block text-base tracking-widest uppercase">
                        Share —{' '}
                      </p>
                      <FacebookShareButton
                        url={siteurl + '/projects/' + post.slug}
                        quote={post.title}
                        hashtag={'#usqualityconstruction'}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Facebook size={20} />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={siteurl + '/projects/' + post.slug}
                        title={post.title}
                        hashtags={post.tags}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Twitter size={20} />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={siteurl + '/projects/' + post.slug}
                        title={post.title}
                        quote={post.title}
                        hashtags={post.tags}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Linkedin size={20} />
                      </LinkedinShareButton>
                      <EmailShareButton
                        subject={post.title}
                        url={siteurl + '/projects/' + post.slug}
                        openWindow={true}
                        body={'Check out this article: '}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Mail size={20} className="stroke-white" />
                      </EmailShareButton>
                    </ShareThisPost>
                    <div className="flex items-center">
                      <button
                        className="flex items-center justify-self-end bg-white border-none text-gvd-500 font-bold outline-none cursor-pointer"
                        onClick={() => toggleLightbox(selectedIndex)}
                      >
                        <span className="hidden md:block text-black text-base tracking-widest uppercase">
                          FullScreen
                        </span>{' '}
                        <Maximize className="ml-2" size={20} />
                      </button>
                    </div>
                    <ModalGateway>
                      {lightboxIsOpen && (
                        <Modal
                          onClose={() => toggleLightbox(selectedIndex)}
                          allowFullscreen
                        >
                          <Carousel
                            currentIndex={selectedIndex}
                            frameProps={{ autoSize: '100vh' }}
                            styles={{
                              view: (base, state) => ({
                                ...base,
                                width: 'auto',
                              }),
                            }}
                            views={items}
                          />
                        </Modal>
                      )}
                    </ModalGateway>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3"></div>
            </div>
          </Header>
          <div className="lg:flex lg:container lg:row mx-auto">
            <div
              className="relative w-full lg:w-2/3 z-10"
              style={{ marginTop: '100px' }}
            >
              {(post.review || post.beforeAndAfter) && (
                <div className="container">
                  <div className="row">
                    {post.review && (
                      <div
                        className={`w-full flex-col p-3 mb-0 mt-0 pt-0 row ml-0 mr-0`}
                      >
                        {post.review && (
                          <>
                            <h3>Customer Review</h3>
                            <ReviewForInModal
                              item={{ node: post.review }}
                              itemKey={post.review.id}
                              projectsPage
                            />
                          </>
                        )}
                      </div>
                    )}
                    {post.beforeAndAfter && post.beforeAndAfter.length > 0 && (
                      <div
                        className={`col-12 col-sm-12 lg:p-4 flex-row items-center mb-0 mt-4 lg:mt-0 pt-0`}
                      >
                        <h3>Before and After Photos</h3>
                        <div className="grid gap-3 grid-cols-2">
                          {post.beforeAndAfter.length > 0 &&
                            post.beforeAndAfter.map(({ before, after }, i) => (
                              <div
                                className={`col-span-2 lg:col-span-1`}
                                key={i}
                              >
                                <ReactCompareImage
                                  leftImage={getSrc(before)}
                                  leftImageLabel={'Before'}
                                  rightImage={getSrc(after)}
                                  rightImageLabel={'After'}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* <div
              style={{ marginTop: '110px' }}
              itemProp="articleBody"
              dangerouslySetInnerHTML={{ __html: post.body.body }}
            />
            <p>
              Tags:{' '}
              {post.tags.map((item, i) => (
                <span itemProp="keywords" key={i}>
                  {' ' + item},
                </span>
              ))}
            </p> */}
            </div>
            <div className="w-full lg:w-1/3"></div>
            <div className="lg:container block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
              <div className="w-full lg:w-2/3 invisible" />
              <div className="container w-full lg:w-1/3 py-12 relative justify-self-end p-4 lg:p-6 pt-14">
                {post && post.videoTestimonial && (
                  <>
                    <h3 className="text-center mb-0 mt-3">
                      Watch Video Testimonial
                    </h3>
                    <div className="py-8 md:pb-6 pt-3 self-center">
                      <TestimonialVideo
                        id={0}
                        thumbnail={post.videoTestimonial.thumbnail}
                        video={post.videoTestimonial.video}
                        className="w-full"
                        rounded
                        projectsPage
                        responsive
                      />
                    </div>
                  </>
                )}
                <ContactCard className="border-radius p-6 cardborder shadow-lg sticky top-0">
                  <span className="block text-center text-base font-semibold mb-1">
                    Need Help With Your Project?
                  </span>
                  <h3 className="text-center">Request a FREE Quote!</h3>
                  <div className="mb-6 sidebarform">
                    <ContactForm
                      showLabels
                      showPlaceHolders
                      pageLocation={
                        props.location.href
                          ? props.location.href
                          : 'Not provided'
                      }
                    />
                  </div>
                </ContactCard>
              </div>
            </div>
          </div>
        </article>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                {
                    "@context": "https://www.schema.org",
                    "@type": "PostalAddress",
                    "addressLocality": "${city}",
                    "addressRegion": "${state}",
                    "postalCode": "${post.zipCode}"
                  }
        `,
          }}
        />
      </BlogPostLayout>
      <div className="container mx-auto p-3 py-8">
        <h3>See some of our other {post.categories[0].name} projects</h3>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {moreInCategory &&
            moreInCategory.edges &&
            moreInCategory.edges.map((item, i) => (
              <Link
                key={i}
                to={`/projects/${item.node.slug}/`}
                className="bg-white border-radius shadow hover:shadow-lg"
              >
                <GatsbyImage
                  image={getImage(item.node.heroImage)}
                  style={{ height: '150px' }}
                  alt={item.node.title}
                  className="border-radius-top"
                />
                <div className="p-3 py-4 pt-3">
                  <span className="text-gray-700 text-base">
                    {item.node.publishDate}
                  </span>
                  <span className=" font-semibold leading-tight text-gray-900 block">
                    {item.node.title}
                  </span>
                  <div className="flex items-center text-gray-800 text-xs mt-1">
                    <div className="">
                      <img
                        src="/assets/location-pin.svg"
                        className="w-2 mb-0"
                      />
                    </div>
                    {item.node.city} {item.node.zipCode}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
      <RelatedBlogPosts props={props} data={props.data.relatedBlogPosts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectPage(
    $slug: String!
    $categoryId: [String]
    $categorySlugExp: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulProjects(slug: { eq: $slug }) {
      id
      title
      slug
      categories: category {
        id
        name
        slug
      }
      date
      city
      zipCode
      thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      heroImage: thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      photoGallery {
        id
        photos {
          id
          smallerFluid: gatsbyImageData(
            quality: 70
            placeholder: NONE
            height: 1000
          )
          fullscreen: gatsbyImageData(quality: 80, width: 1874)
          file {
            url
          }
          gatsbyImageData(quality: 70, width: 1000, placeholder: NONE)
        }
      }
      description {
        id
        description
      }
      videoTestimonial {
        id
        thumbnail {
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        video {
          file {
            url
            contentType
          }
        }
      }
      review {
        id
        source
        date
        reviewTitle
        reviewer
        source
        date
        category {
          id
          name
          slug
        }
        reviewBody {
          id
          reviewBody
        }
      }
      beforeAndAfter {
        id
        before {
          id
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        after {
          id
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
      }
    }
    moreInCategory: allContentfulProjects(
      filter: {
        category: { elemMatch: { id: { in: $categoryId } } }
        slug: { ne: $slug }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          city
          zipCode
          heroImage: thumbnail {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
        }
      }
    }
    relatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { regex: $categorySlugExp } } }
      limit: 4
      skip: 6
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            id
            slug
            name
          }
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
          author {
            name
          }
        }
      }
    }
  }
`

export default ProjectPage
